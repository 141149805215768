var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fileUploader" }, [
    _c("div", { class: ["progressBar", { active: _vm.uploading }] }, [
      _c("div", {
        staticClass: "progressBarInner",
        style: "transform: translateX(" + (_vm.progress || 0) + "%)"
      })
    ]),
    _c(
      "div",
      { staticClass: "text-right uploaderArea" },
      [
        _c(
          "BaseButton",
          {
            attrs: { type: "green", gradient: "" },
            on: { click: _vm.toggleUploader }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("addNewItem", { item: _vm.$tc("file", 2) })) +
                " "
            )
          ]
        )
      ],
      1
    ),
    _vm.isUploaderVisible
      ? _c("div", { staticClass: "uploaderAreaInner" }, [
          _vm._v(" " + _vm._s(_vm.$t("clickToAddFilesOrDropThemHere")) + " "),
          _c("input", {
            ref: "inputFiles",
            staticClass: "inputFiles",
            attrs: { id: "inputFiles", type: "file", multiple: "" },
            on: {
              change: function($event) {
                return _vm.handleInputFiles()
              }
            }
          })
        ])
      : _vm._e(),
    _c(
      "div",
      {
        class: ["dropAreaContainer", { active: _vm.isDropAreaVisible }],
        on: {
          dragover: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.dragenter.apply(null, arguments)
          }
        }
      },
      [
        _vm.isDropAreaVisible
          ? _c(
              "div",
              {
                staticClass: "dropArea",
                on: {
                  dragleave: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.dragleave.apply(null, arguments)
                  },
                  drop: function($event) {
                    $event.preventDefault()
                    return _vm.drop($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "dropAreaInner" }, [
                  _vm._v(" " + _vm._s(_vm.$t("dropFilesHere")) + " ")
                ])
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }